import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api/api.service';
import { ActivityI } from './interfaces/activity.interface';
import { AddActivityRequestBodyI } from './interfaces/add-activity-request-body.interface';
import { DeleteActivityQueryI } from './interfaces/delete-activity-query.interface';
import { EditActivityRequestBodyI } from './interfaces/edit-activity-request-body.interface';
import { AolReportI } from './interfaces/aol-report.interface';

@Injectable({
  providedIn: 'root',
})
export class ActivitiesApiService {
  constructor(private readonly apiService: ApiService) {}

  findAll(courseSectionID: string | number) {
    return this.apiService.get<ActivityI[]>(
      `activities/course-section/${courseSectionID}`
    );
  }

  create(
    courseSectionID: string | number,
    addActivityRequestBodyI: AddActivityRequestBodyI
  ) {
    return this.apiService.post<ActivityI>(
      `activities/${courseSectionID}`,
      addActivityRequestBodyI
    );
  }

  update(
    activityID: string,
    editActivityRequestBodyI: EditActivityRequestBodyI
  ) {
    return this.apiService.patch<ActivityI>(
      `activities/${activityID}`,
      editActivityRequestBodyI
    );
  }

  delete(activityID: string, deleteActivityQuery: DeleteActivityQueryI) {
    return this.apiService.delete<ActivityI>(
      `activities/${activityID}`,
      deleteActivityQuery
    );
  }

  findOne(activityID: string) {
    return this.apiService.get<ActivityI>(`activities/${activityID}`);
  }

  aolActivityReport(activityID: string) {
    return this.apiService.get<AolReportI>(
      `activities/${activityID}/aol/activity-report`
    );
  }
}
